<template>
    <CRow class="mt-2"> 
        <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
        <CCol sm="7" class="form-inline">
            <label class="mr-2">
                {{$t('label.heading')}}:
            </label>
            <v-select
                :options="HeadingOptions"
                v-model="HeadingId"
                :placeholder="$t('label.toSelect')"
                class="w-75"
            />
        </CCol>
        <CCol sm="5" class="text-right">
            <CButton 
                square 
                color="add" 
                @click="OpenCommodity(true)"
                v-c-tooltip = "{ placement:'top-end', content:$t('label.commodity')}"
            >
                <CIcon name="cil-playlist-add"/>&nbsp;{{$t("label.nueva")}}
            </CButton>
        </CCol>
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="ComputedCommodity"
                :fields="Fields"
                column-filter
                :noItemsView="tableText.noItemsViewText"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items-per-page="tableText.itemsPerPage"
                hover
                small
                sorter
                pagination
            > 
                <template #Logo="{ item }">
                    <td class="text-center">
                        <c-img-extended 
                            :src="item.Logo"
                            :key="item.Nro"
                            :error-options="{ width: 40, height: 40, fontSize: 8 }"
                            thumbnail
                            width="45"
                            height="45"
                        />
                    </td>
                </template>
                <template #Status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                </template>
                <template #Detalle="{item}">
                    <td class="py-2 center-cell">
                        <CButton 
                            color="edit" 
                            style="margin-right:2px;" 
                            square 
                            size="sm" 
                            v-c-tooltip="{placement:'top-end', content: $t('label.edit')+' '+ $t('label.commodity')}" 
                            @click="OpenCommodity(item)" >
                            <CIcon name="pencil" />
                        </CButton>
                        <CButton 
                            color="add" 
                            style="margin-right:2px;" 
                            square size="sm" 
                            v-c-tooltip="{placement:'top-end',content:$t('label.uploadCommodityDocuments')}" 
                            @click="LoadManual=item">
                            <CIcon name="cil-cloud-upload" />
                        </CButton>
                        <CButton 
                            color="watch" 
                            square 
                            size="sm" 
                            v-c-tooltip="{placement:'top-end',content:$t('label.see')+ $t('label.pdfManuals')}" 
                            @click="ShowManual=item" >
                        <CIcon name="eye" />
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
        <CommodityManual 
            :modal="ShowCommodityModal" 
            @cerrarModal="ShowCommodityModal=false" 
            @child-refresh="RefreshCommodity" 
        />
        <ManualListModal 
            :modal="ShowManual" 
            @cerrarModal="ShowManual=false" 
        />
        <UploadCommodityManualModal 
            :modal="LoadManual" 
            @cerrarModal="LoadManual=false" 
        />
    </CRow>
</template>
<script>
import { DateFormater, GenerateImgPlaceholder } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import CImgExtended from '@/components/CImgExtended';
import Fuse from "fuse.js";
import CommodityManual from './commodity-modal';
import ManualListModal from '../manual-list-modal';
import UploadCommodityManualModal from '../upload-commodity-manual-modal';

function data() {
    return {
        CommodityList: [],
        Loading: false,
        CommodityList: [],
        ShowHeadingModal: false,
        HeadingList: [],
        HeadingId: '',
        ShowCommodityModal: false,
        ShowManual: false,
        LoadManual: false,
    };
}
//methods
function handleTab(tab) {
    this.Tab = tab;
}


async function getService(Heading) {
  this.Loading = true;
  let requests = [
    this.$http.ejecutar("GET", "Commodity-list", { HeadingId: Heading }),
    this.$http.ejecutar("GET", "Heading-list", { Filter: 'ACTIVO' }),
  ];
  await Promise.all(requests)
     .then((responses) => {
      this.CommodityList = responses[0].data.data ?? [];
      this.HeadingList = responses[1].data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}



async function getCommodityList(Heading) {
  this.Loading = true;
  await this.$http
  .get('Commodity-list',{ HeadingId: Heading })
    .then((response) => (
        this.CommodityList = response.data.data
    ))
    .finally(() => {
        this.Loading = false;
    });  
}

function OpenCommodity(item) {
  this.ShowCommodityModal = {Item: item, Rubros: this.HeadingList };
}

function RefreshCommodity(newVal) {
  this.getCommodityList(newVal);
}

//computed

function ComputedCommodity() {
    return this.CommodityList.map((item) => {
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return {
            Nro: item.Nro,
            CommodityId: item.CommodityId,
            HeadingName: item['HeadingName'+_lang], 
            Logo: item.CommodityRoute ? `${this.$store.getters["connection/getBase"]}${item.CommodityRoute?item.CommodityRoute.replace('Public/', '', 'i'):''}` : GenerateImgPlaceholder(),
            CommodityRoute: item.CommodityRoute,
            HeadingId: item.HeadingId,
            CommodityNameEs: item.CommodityNameEs ?? 'N/A',
            CommodityNameEn: item.CommodityNameEn ?? 'N/A',
            HsCode: item.HsCode ?? 'N/A',
            Usuario: item.TransaLogin ? item.TransaLogin:'N/A',
            Fecha: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            Status: item.Status,
            CommodityDs: item.CommodityDs,
            Density: item.Density,
            FgActCommodity: item.FgActCommodity,
            _classes: item.Status === "INACTIVO" ? 'table-danger': ''
        };
    });
}
function Fields(){
    return [
        { key: 'Nro', label: '#',_style: 'width:3%; text-align:center', filter: false },
        { key: 'HeadingName', label:this.$t('label.heading'), _classes: 'text-uppercase text-center', _style:'width:25%' },
        { key: 'CommodityNameEs', label:`${this.$t('label.commodity')} (ES)`,_classes: 'text-uppercase text-center', _style:'width:25%' },
        { key: 'CommodityNameEn', label:`${this.$t('label.commodity')} (EN)`,_classes: 'text-uppercase text-center', _style:'width:25%' },
        { key: 'Logo', label: this.$t('label.image'), sorter: false, filter: false, _style:'width:5%; min-width:50px' },
        { key: 'Usuario', label:this.$t('label.user'),_classes: 'text-uppercase text-center', _style:'width:10%' },
        { key: 'Fecha', label:this.$t('label.date'), _classes:'text-center', _style:'width:10%' },
        { key: 'Status', label:this.$t('label.status'),  _classes: 'text-center text-uppercase', _style:'width:10%' },
        { key: 'Detalle',label: "",_style: 'width:10%; min-width:135px;', sorter: false, filter: false },
    ];
}

function HeadingOptions(){
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.HeadingList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.HeadingList.map((e)=>{
        if (e.FgActHeading) {
          chart.push({
            value: e.HeadingId,
            label: e['HeadingName'+_lang],
        })
      }    
    })
    return chart;
  }
}


export default {
name: 'index',
components: { 
  CommodityManual,
  ManualListModal,
  UploadCommodityManualModal,
  CustomTabs, 
  CustomTab,
  CImgExtended
},
data,
props:{Tab: Number},
mixins: [GeneralMixin],
methods: {
  getService,
  getCommodityList,
  OpenCommodity,
  handleTab,
  RefreshCommodity
},
computed: {
  ComputedCommodity,
  Fields,
  HeadingOptions
},
watch:{
    HeadingId: function(item) {
      let valor;
      if (item) {
        valor = item.value;
      } else {
        valor = '';
      }
      this.getCommodityList(valor);
    },
    Tab: async function (val){
        if(val == 1){
            this.HeadingId = '',
            this.HeadingList = [],
            await this.getService('')
        }
    }
},
};
</script>
<style scoped>
  .img-logo {
    width: 50px;
  }
</style>
