<template>
  <div>
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="VerManual"
    >
      <CRow class="m-2">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="Loading"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            small
            hover
            sorter
            pagination
          >
            <template #loading >
              <loading/>
            </template>
            <template #Down="{item}">
              <td class="text-center">
                <a class="btn btn-sm btn-watch" v-c-tooltip="{
                            content: $t('label.openDocumentation')
                          ,placemen:'top-end'}" target='_blank' :href="item.Down" >
                  <CIcon name="eye" />
                </a>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="wipe" @click="VerManual= false">
            <CIcon name="x"/>&nbsp;
             {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';

function fields(){

  return[
  { key: 'Nro', label: '#',_style: 'width:3%; text-align:center', filter: false },
  { key: 'RouteCommodityDoc', label:this.$t('label.handlingManuals'), _classes: 'text-uppercase text-center', _style:'width:50%' },
  { key: 'Usuario', label:this.$t('label.user'),_classes: 'text-uppercase text-center', _style:'width:25%' },
  { key: 'Fecha', label:this.$t('label.date'),_classes:'text-center', _style:'width:25%' },
  { key: 'Down', label: "",_style: 'width:5%; min-width:45px;', sorter: false, filter: false },
]
};
function data () {
  return {
    items: [],
    CommodityId: '',
    Loading: false,
    VerManual: false,
    tituloModal: null
  }
}

//FUNCIONES DE METHOD
function filtroItems(listado) {
  this.items = listado
  .filter(listado => listado.FgActCommodityDoc)
  .map(listado => Object.assign({}, this.items, {
    Nro: listado.Nro,
    CommodityDocId: listado.CommodityDocId,
    RouteCommodityDoc: listado.CommodityDocDs ? listado.CommodityDocDs:'N/A',
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Down: listado.RouteCommodityDoc ? `${this.$store.getters["connection/getBase"]}${listado.RouteCommodityDoc.replace('Public/', '', 'i')}`:'N/A',
  }));

  return this.items;
}

function listarDatos () {
  this.Loading = true;
  let listado = Array;
  this.$http.get("CommodityDoc-list", { CommodityId: this.CommodityId })
  .then(response => {
    listado = [...response.data.data];
    this.filtroItems(listado);
    this.Loading= false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
  });
}

function filtroVerManuales() {
    let i=1;
    return this.items.map((item) => Object.assign({}, item, {
      Fila: i++,
      _classes: item.Status === "INACTIVO" ? 'table-danger': ''
    }));
}

export default {
  name: 'manual-list-modal',
  mixins: [General],
  data,
  props:{
    modal:null
  }, 
  methods: {
    listarDatos,
    filtroVerManuales,
    filtroItems
  },
  watch: {
    modal: function () {
      if (this.modal) {
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        this.VerManual = true;
        this.CommodityId = this.modal.CommodityId;
        this.listarDatos();
        this.tituloModal= this.$t('label.commodityHandlingDocumentation') + ": "+this.modal['CommodityName'+_lang];
        this.$emit('cerrarModal');
      }
    }
  },
  computed:{
    fields
  }
}
</script>
<style scoped>

</style>